import React from "react"
import Layout from "../components/layout"

const ContactUsPage = () => {
    return (
        <Layout title="Contact Us">
            <div className="mx-auto container">
                <h1>Contact Us</h1>
                <hr className="mb-4" />
                To contact us, please email{" "}
                <a href="mailto:jmedina@hsdpcb.com">jmedina@hsdpcb.com</a>.
            </div>
        </Layout>
    )
}

export default ContactUsPage
